import React, { useState, useEffect, useMemo } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'

import Layout from "../components/layout"
import Seo from "../components/seo"
import BaseCategories from "../components/posts/categories"
import BasePost from "../components/posts/post"

const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/\/posts\/[^/]+.md$/i" }},
      limit: 1000,
      sort: { fields: [frontmatter___date], order: DESC}
    ) {
      nodes {
        excerpt
        frontmatter {
          excerpt
          date(formatString: "MMMM DD, YYYY")
          path
          title
          categories
          cover {
            childImageSharp {
              fluid(maxWidth: 750, maxHeight: 280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const Categories = styled(BaseCategories)`
  margin-bottom: 36px;
`

const Post = styled(BasePost)`
  margin-top: 72px;
  &:first-child {
    margin-top: 0;
  }
`

const Content = styled.div`
`

const Posts = () => {
  const data = useStaticQuery(pageQuery)
  const [filter, setFilter] = useState(null)
  const queriedPosts = useMemo(() => data.allMarkdownRemark?.nodes || [], [data.allMarkdownRemark?.nodes])
  const [posts, setPosts] = useState(queriedPosts)

  useEffect(() => {
    if(!filter) {
      setPosts(queriedPosts)
      return
    }

    const filteredPosts = queriedPosts.filter(p => p.frontmatter.categories.indexOf(filter) >= 0)
    setPosts(filteredPosts)
  }, [queriedPosts, filter])

  return (
    <Layout>
      <Seo title="Michael Yagudaev - Posts" />
      <h1>Posts</h1>
      <Categories filter={filter} posts={queriedPosts} onCategoryClick={(category) => setFilter(category)} />
      <Content>
        {posts.map((post) => {
          return <Post key={post.frontmatter.path} post={post} />
        })}
      </Content>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export default Posts
